<template>
  <!--支付登记页-->
  <div class="table-responsive mt-5">
    <div class="d-flex align-items-center">
      <!-- 左边 -->
      <el-button-group>
        <el-button type="success" size="mini" @click="order_status = 3"
          >待登记</el-button
        >
        <el-button type="success" size="mini" @click="order_status = 4"
          >已登记</el-button
        >
      </el-button-group>
      <!-- 右边 -->
      <div class="ml-auto">
       <span>选择日期：</span>
        <date-picker @select-change="datepickerselect"></date-picker>
        <el-input
          v-model="formdata.name"
          placheholder="要搜索的供应商名称"
          size="mini"
          style="width: 150px"
          class="mr-2 ml-3"
        ></el-input>
        <el-button type="info" size="mini">搜索</el-button>
      </div>
      <!-- 最右边 -->
      <router-link :to="{ name: 'payments_spayments_create' }">
        <el-button style="margin-left: 5px" type="success" size="mini"
          >申请付款</el-button
        >
      </router-link>
    </div>

    <el-table
      border
      class="mt-3"
      :data="tableData.data"
      style="width: 100%"
      :show-summary="true"
      :summary-method="summaryMethod"
      height="650"
    >
      <el-table-column label="状态" width="180" align="center">
        <template #default="scope">
          <el-button
            size="small"
            type="warning"
            round
            v-if="scope.row.order_status === 3"
            >待登记</el-button
          >
          <el-button
            size="small"
            type="danger"
            round
            v-if="scope.row.order_status === 4"
            >已登记</el-button
          >
          <el-button size="small" plain @click="pdfview(scope.row.id)"
            >查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180" align="center">
        <template #default="scope">
          <el-button
            size="small"
            plain
            v-if="scope.row.order_status === 3"
            @click="revocation_pass(scope.row.id)"
            >核对登记</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="sq_date"
        label="日期"
        sortable
        width="100"
        align="center"
      />
      <el-table-column
        prop="id_no"
        label="凭证编号"
        width="140"
        align="center"
      />
      <el-table-column
        prop="SupplierInfo.supplier_name"
        sortable
        label="供应商名称"
        width="240"
        align="center"
      />
      <el-table-column
        prop="sq_amount"
        label="付款金额"
        width="180"
        align="center"
        :formatter="toThousands"
      />
      <el-table-column
        prop="category_id_t"
        sortable
        label="成本类别"
        width="180"
        align="center"
      />
      <el-table-column
        prop="fk_text"
        label="付款说明"
        width="180"
        align="center"
      />

      <el-table-column
        prop="Managerproposer.usealias"
        label="申请人"
        width="180"
        align="center"
      />
      <el-table-column
        prop="Managerapprover.usealias"
        label="批准人"
        width="180"
        align="center"
      />
      <el-table-column
        prop="Managercfo.usealias"
        label="审核人"
        width="180"
        align="center"
      />
      <el-table-column
        prop="Managerregistrant.usealias"
        label="付款人"
        width="180"
        align="center"
      />
    </el-table>
  </div>
  <!-- 查看PDF文件List -->
  <el-dialog v-model="pdfurlVisible" width="100%" fullscreen>
    <pdf-view fileclass="2" :prdclassid="prdclassid"></pdf-view>
  </el-dialog>
  <div style="height: 60px"></div>
  <!-- 占位底部60PX -->
  <el-footer
    class="border-top d-flex align-items-center px-0 position-fixed bg-white"
    style="bottom: 0; left: 150px; right: 0; z-index: 100"
  >
    <el-config-provider :locale="locale">
      <el-pagination
        v-model:currentPage="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="[5, 10, 20, 100, 300]"
        layout="sizes, prev, pager, next,total,jumper"
        :total="lotalrows"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        prev-text="上一页"
        next-text="下一页"
        background
      >
      </el-pagination
    ></el-config-provider>
  </el-footer>
</template>

<script>
import axios from "axios";
import { ElMessageBox } from "element-plus";
import zhCnelementpage from "element-plus/lib/locale/lang/zh-cn";
export default {
  data() {
    return {
      //分页数据
      pageSize: 10, //每页数据 默认10条
      currentPage: 1, //当前页码  默认第1页
      lotalrows: 0, //总行数
      locale: zhCnelementpage, //中文分页
      //
      //
      project_id: window.sessionStorage.getItem("project_ids"),
      user_id: JSON.parse(window.sessionStorage.getItem("user")).id,
      order_status: 3,
      //DatePicker 日期选择器
      startDate:'1900-1-1',
      endDate:'2099-12-31',
      //
      formdata: {
        name: "",
      },
      //
      tableData: {
        data: [],
      },
      prdclassid: "", //查看附件记录的ID
      pdfurlVisible: false, //查看附件列表开关
    };
  },
  watch: {
    order_status() {
      this.loadtabledata(this.order_status, this.pageSize, this.currentPage);
    },
  },
  created() {
    //参数3 表示：待支付登记状态
    this.loadtabledata(3, this.pageSize, this.currentPage);
  },
  methods: {
    //DatePicker 日期选择器事件
    datepickerselect(val){
      this.startDate = val[0]
      this.endDate = val[1]
      console.log('日期选择器事件',this.startDate,this.endDate)
    },
    //选择页码选项
    handleCurrentChange(val) {
      console.log("选择页码选项", val);
      this.currentPage = val;
      this.loadtabledata(this.order_status, this.pageSize, this.currentPage);
    },
    //选择每页条数
    handleSizeChange(val) {
      console.log("选择每页条数", val);
      this.pageSize = val;
      this.loadtabledata(this.order_status, this.pageSize, this.currentPage);
    },
    //核对支付登记
    revocation_pass(rowid) {
      //打开核对支付登记页面
      window.sessionStorage.setItem("rowid", rowid);
      this.$router.push({ name: "payments_zpayments_zpupdate" });
    },

    //初始加载数据
    loadtabledata(status_id, rows, pags) {
      axios
        .get("admin/FkRequest", {
          params: {
            project_id: this.project_id,
            order_status: status_id,
            page: pags,
            list_rows: rows,
            startDate:this.startDate,
            endDate:this.endDate,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******", response.data, "******");
          this.currentPage = response.data.current_page; //当前页码  默认第1页
          this.lotalrows = response.data.total; //总行数
          this.tableData.data = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //更新记录状态  暂时保留 没有用
    updatetabledata(idval, status, load_status) {
      let urlstr = "admin/FkRequest/" + idval + "/update_status";
      axios
        .post(
          urlstr,
          { order_status: status, registrant_id: this.user_id },
          {
            headers: {
              token: window.sessionStorage.getItem("token"),
            },
          }
        )
        // .then((response) => {
        .then(() => {
          ElMessageBox.alert("操作成功！", "提示", {
            confirmButtonText: "OK",
          });
          //重新加载数据
          this.loadtabledata(load_status);
        })
        //.catch((error) => {
        .catch(() => {
          ElMessageBox.alert("操作失败！", "提示", {
            confirmButtonText: "OK",
          });
        })
        .then(() => {
          // 总是会执行
        });
    },
    //格式化数字
    toThousands(cellValue) {
      console.log(cellValue);
      return cellValue.sq_amount.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });
    },
    //查看pdf清单列表
    pdfview(vals) {
      console.log("*********", vals);
      this.prdclassid = vals;
      this.pdfurlVisible = true;
    },
    //合计行
    summaryMethod(param) {
      let arr = [];
      let totalsum5 = 0;

      param.data.forEach((element) => {
        totalsum5 += element.sq_amount;
      });

      arr[0] = "合计";

      arr[5] = totalsum5.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });

      return arr;
    },
  },
};
</script>

<style></style>
